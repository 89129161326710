import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import phoneicon from "../../../assets/images/popup/phone-icon.png";
import AdminService from "../../../components/services/admin.service";
import { toast, ToastContainer } from "react-toastify";
import { lStorage } from "../../../components/utils/storage";

const AdminLoginPopup = () => {
  const adminApi = new AdminService();

  const [mobile, setMobile] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [showLoginPopup, setShowLoginPopup] = useState(true);
  const [isOtp, setIsOtp] = useState(false);
  const [adminVerificationId, setAdminVerificationId] = useState(false);
  const [formErrors, setFormErrors] = useState();
  const [otpError, setOtpErrors] = useState();

  const handleClose = () => {
    navigate("/");
    setShowLoginPopup(false);
  };
  const buttonname = "SEND OTP";
  const buttonverfify = "VERIFY & PROCEED";

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm();

  const resendOTP = () => {
    setSeconds(59);
    setMinutes(0);
    const payLoad = {
      mobile_number: mobile,
    };
    adminApi.adminlogin(payLoad).then((res) => {
      if (res.data.isError === false || res.data.statusCode === "200") {
        if (res.data.isError === false) {
          setIsOtp(true);
          toast.success(res?.data?.message);
          setAdminVerificationId(res?.data?.data?.verification_id);
        } else {
          toast.error(res.data.message);
        }
      } else {
        setFormErrors(res.data.message?.[0]);
      }
    });
  };

  const onSubmit = (data) => {
    setMobile(data.mobile_number);
    const payLoad = {
      mobile_number: data.mobile_number,
    };
    adminApi.adminlogin(payLoad).then((res) => {
      if (res.data.isError === false || res.data.statusCode === "200") {
        if (res.data.isError === false) {
          setIsOtp(true);
          toast.success(res?.data?.message);
          setAdminVerificationId(res?.data?.data?.verification_id);
        } else {
          toast.error(res.data.message);
        }
      } else {
        setFormErrors(res.data.message?.[0]);
      }
    });
  };

  const onSubmittwo = (data) => {
    const payLoad = {
      verification_id: adminVerificationId,
      verification_code: Number(data?.otpNumber),
    };
    adminApi.adminotpverification(payLoad).then((res) => {
      if (res.data.isError === false || res.data.statusCode === "200") {
        lStorage.remove("userInfo");
        toast.success(res?.data?.message);
        lStorage.set("adminInfo", res.data.data);
        navigate("/adminui/bibdetails");
      } else {
        if (res.data.isError === true) {
          setOtpErrors(res.data.message[0]);
        }
      }
    });
  };

  useEffect(() => {
    if (isOtp) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, isOtp, minutes]);

  return (
    <div>
      <Modal
        show={showLoginPopup}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="register-popup"
      >
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Login</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isOtp && (
            <>
              <div className="contact-wrapper-box">
                <img
                  className="run-logo-custom"
                  src={phoneicon}
                  alt="Run logo"
                  style={{ width: "38px" }}
                />
                <h4>Phone Number</h4>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    name="mobile_number"
                    id="mobile_number"
                    autoComplete="new-password"
                    {...register("mobile_number", {
                      required: true,
                      pattern: {
                        value: /^[6-9]{1}[0-9]{9}$/,
                        message: "Please enter valid Phone number",
                      },
                    })}
                    onChange={() => {
                      setFormErrors("");
                      clearErrors("mobile_number");
                    }}
                  />
                  <span style={{ paddingTop: "5px", position: 'relative', top: '5px' }}>
                    Example : 98766XXXXX
                  </span>
                  <br />
                  <span className="error_validation ml-3">
                    {errors.mobile_number?.type === "required" &&
                      "This field is required."}
                    {errors.mobile_number?.message}
                  </span>
                  {formErrors && (
                    <span className="error_validation ml-3">{formErrors} </span>
                  )}
                </div>
                <div className="cent-wrapper padding-cent-wrpper">
                  <input
                    type="submit"
                    className="btn btn-orange"
                    value={buttonname}
                  />
                </div>
              </form>
            </>
          )}
          {isOtp === true && (
            <>
              <div>
                <p className="otp-name">
                  {" "}
                  Please enter the OTP send to your Mobile no
                </p>
                <div className="otp-box-wrpper">
                  <form onSubmit={handleSubmit2(onSubmittwo)}>
                    <div className="form-group">
                      <input
                        type="number"
                        {...register2("otpNumber", {
                          required: true,
                          pattern: {
                            value: /^\d{6}$/,
                            message: "Please enter valid OTP number",
                          },
                        })}
                        className="form-control"
                        onChange={() => {
                            setOtpErrors("");
                            clearErrors("otpNumber");
                        }}
                      />
                      <span className="error_validation ml-3">
                        {errors2.otpNumber?.type === "required" &&
                          "This field is required."}
                        {errors2.otpNumber?.message}
                      </span>
                    </div>
                    <span className="error_validation ml-3">
                      {otpError && <span>{otpError}</span>}
                    </span>
                    <div className="otp-button">
                      {seconds > 0 || minutes > 0 ? (
                        <p>
                          Time Remaining for Resend OTP:{" "}
                          {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="otp-button">
                      {seconds > 0 || minutes > 0 ? (
                        <></>
                      ) : (
                        <button
                          type="button"
                          className=" btn btn-secondary btn-block"
                          value="Resend OTP"
                          onClick={resendOTP}
                        >
                          Resend OTP
                        </button>
                      )}
                      &nbsp;&nbsp;
                      <input
                        type="submit"
                        className="btn btn-orange"
                        value={buttonverfify}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default AdminLoginPopup;
